.t1 {
  width: 100%;
  background: #002fa7;
  height: 160px;
  border-bottom: 1px solid #93a3b8;
  padding: 30px;
}

.t2 {
  width: 1200px;

  margin: 0 auto 0;
  display: flex;
}

.left {
  width: 200px;
  background: url("//static.taocheche.com/taoche/pc/assets/images/logo.png")
    no-repeat top left/126px 30px;
  padding-top: 50px;

  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: left;
  font-weight: 400;
}
.center1 {
  width: 100px;
}

.txt1 {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: left;
  font-weight: 400;
  line-height: 25px;
  cursor: pointer;
  position: relative;
  display: block;
}

.txt11 {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: left;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.txt2 {
  width: 400px;
  display: flex;
}
.txt22 {
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: left;
  font-weight: 600;
  line-height: 30px;
}

.txt23 {
  padding-left: 22px;
  font-family: PingFangSC-Regular;
  line-height: 30px;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  margin-left: 30px;
  cursor: pointer;

  background: url("//static.taocheche.com/taoche/pc/assets/images/weixin.png")
    no-repeat center left/17px 17px;
}

.txt24 {
  padding-left: 22px;
  font-family: PingFangSC-Regular;
  line-height: 30px;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  margin-left: 30px;
  cursor: pointer;
  background: url("//static.taocheche.com/taoche/pc/assets/images/douyin.png")
    no-repeat center left/17px 17px;
}

.txt25 {
  padding-left: 22px;
  font-family: PingFangSC-Regular;
  line-height: 30px;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  margin-left: 30px;
  cursor: pointer;
  background: url("//static.taocheche.com/taoche/pc/assets/images/headset.png")
    no-repeat center left/17px 17px;
  a {
    color: #ffffff;
  }
}
.txt33 {
  font-family: PingFangSC-Semibold;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: left;
  font-weight: 600;
  line-height: 25px;
  margin-top: 20px;
}

.center2 {
  width: 600px;
  position: relative;
}

.right {
  width: 299px;
  display: flex;
  justify-content: flex-end;
}
.rightButton {
  width: 160px;
  height: 50px;
  line-height: 50px;
  background: #ffe900;
  font-family: PingFangSC-Semibold;
  font-size: 20px;
  color: #1e293b;
  letter-spacing: 0;
  text-align: center;
  font-weight: 600;
  margin-right: 0;
  cursor: pointer;
}

.f1 {
  width: 100%;
  background: #002fa7;
  height: 110px;

  overflow: hidden;
}

.f2 {
  width: 1200px;
  margin-top: 25px;
  margin: 25px auto 0;
}

.fxt1 {
  font-family: PingFangSC-Regular;
  height: 20px;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
  a {
    color: #ffffff;
    margin-right: 10px;
  }
}

.weixin2 {
  width: 180px;
  height: 206px;
  background: white;
  padding: 10px;
  position: absolute;
  z-index: 10;
  left: 90px;
  top: -210px;
  box-shadow: 0 0 10px 0 rgba(30, 41, 59, 0.6);
}

.douyin2 {
  width: 180px;
  height: 206px;
  background: white;
  padding: 10px;
  position: absolute;
  z-index: 10;
  left: 170px;
  top: -210px;
  box-shadow: 0 0 10px 0 rgba(30, 41, 59, 0.6);
}
.imageDiv{
  width:140px;
  height:149px;
}
.imageDiv2{
  width:160px;
  height:160px;
}
.weixintxt1 {
  margin-top: -8px;
}
.weixintxt4 {
  margin-top: 2px;
}
.weixintxt2 {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #1e293b;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  line-height: 16px;
  transform: scale(0.8);
}
